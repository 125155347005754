import React from "react";
import { sanityConfig } from "@utils/variables";
import BasePortableText from "@sanity/block-content-to-react";

const defaultSerializers = {
  types: {
    span: props => <span>{props}</span>,
    strong: props => <strong>{props}</strong>,
    br: <br/>
  }
};

const PortableText = ({ blocks, serializers }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers ? defaultSerializers : serializers}
    {...sanityConfig}
  />
);

export default PortableText;
